import { useChat } from "ai/react";
import { Provider as StreamingTextProvider, atom } from "jotai";
import React, { useMemo } from "react";
import { createContainer } from "unstated-next";
export type ChatBotAIProviderConfigProps = Pick<ReturnType<typeof useChat>, "isLoading" | "input" | "setInput" | "handleSubmit" | "append" | "messages" | "setMessages">;
export const streamingTextAtom = atom<string | null>(null);
function useContainer(initialState?: {
  chatProps: ChatBotAIProviderConfigProps;
}) {
  const {
    chatProps
  } = initialState as {
    chatProps: ChatBotAIProviderConfigProps;
  };
  const {
    messages,
    ...rest
  } = chatProps;
  const filteredMessages = useMemo(() => messages.filter(Boolean),
  // .filter((message) => !message.toolInvocations?.length)
  // .filter((message) => !message.tool_call_id),
  // .filter((message) => !!message.content || !!message.content),
  [messages]);
  const memoizedChatProps = useMemo(() => ({
    ...rest,
    messages: filteredMessages
  }), [rest, filteredMessages]);
  return memoizedChatProps;
}
export const {
  Provider: ComponentProvider,
  useContainer: useChatBotAIProps
} = createContainer(useContainer);
export const ChatBotAIPropsProvider = ({
  children,
  initialState
}: {
  children: React.ReactNode;
  initialState;
}) => {
  return <StreamingTextProvider data-sentry-element="StreamingTextProvider" data-sentry-component="ChatBotAIPropsProvider" data-sentry-source-file="ChatBotAIPropsProvider.tsx">
      <ComponentProvider initialState={initialState} data-sentry-element="ComponentProvider" data-sentry-source-file="ChatBotAIPropsProvider.tsx">
        {children}
      </ComponentProvider>
    </StreamingTextProvider>;
};