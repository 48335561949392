"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";
import { cn } from "ui/lib/utils";
const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = ({
  ...props
}) => <TooltipPrimitive.Root {...props} data-sentry-element="unknown" data-sentry-component="Tooltip" data-sentry-source-file="tooltip.tsx" />;
Tooltip.displayName = TooltipPrimitive.Tooltip.displayName;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>>(({
  className,
  sideOffset = 4,
  ...props
}, ref) => <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn("z-50 overflow-hidden rounded-md border px-3 py-1.5 text-sm shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=top]:slide-in-from-bottom-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 border bg-secondary text-muted-foreground", className)} {...props} />);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
const Portal = TooltipPrimitive.Portal;
export const TooltipComponent = ({
  children,
  content
}) => {
  return <TooltipPrimitive.Provider data-sentry-element="unknown" data-sentry-component="TooltipComponent" data-sentry-source-file="tooltip.tsx">
      <TooltipPrimitive.Root data-sentry-element="unknown" data-sentry-source-file="tooltip.tsx">
        <TooltipPrimitive.Trigger asChild data-sentry-element="unknown" data-sentry-source-file="tooltip.tsx">{children}</TooltipPrimitive.Trigger>
        <Portal data-sentry-element="Portal" data-sentry-source-file="tooltip.tsx">
          <TooltipPrimitive.Content sideOffset={4} className={cn("radix-side-top:animate-slide-down-fade", "radix-side-right:animate-slide-left-fade", "radix-side-bottom:animate-slide-up-fade", "radix-side-left:animate-slide-right-fade", "inline-flex items-center rounded-md px-4 py-2.5", "bg-secondary shadow-lg border border-neutral-700/50")} data-sentry-element="unknown" data-sentry-source-file="tooltip.tsx">
            <TooltipPrimitive.Arrow className="fill-current text-neutral-800" data-sentry-element="unknown" data-sentry-source-file="tooltip.tsx" />
            <span className="block text-xs leading-none text-foreground">
              {content}
            </span>
          </TooltipPrimitive.Content>
        </Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>;
};
export { Portal, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };