import Link from "next/link";
import { useRouter } from "next/router";
import { ShineBorder } from "ui/components/shine-border";
import AnimatedShinyText from "ui/components/shiny-text";
import { captureEvent } from "ui/lib/captureEvent";
import { cn } from "ui/lib/utils";
import { useExperiment } from "web/state-containers/experiment";

export const promoCodesToCopyText = {
  "30day": "30 Day Free Trial",
  tapestryvc1mo: "30 Day Free Trial",
  shredguestpass30: "30 Day Guest Pass",
  "14day": "14 Day Free Trial",
  baselineguestpass30: "30 Day Guest Pass",
  // "7daysupport": "7 Day Support Trial",
  resetguestpass30: "30 Day Guest Pass",
  reloadguestpass30: "30 Day Guest Pass",
  maxguestpass30: "30 Day Guest Pass",
  capitalcamp6mo: "6 Month Free Trial",
  sustainguestpass30: "30 Day Guest Pass",
  shred30: "30 Day Free Trial",
  "60day": "60 Day Free Trial",
  nutrabolt_free_12mo: "Free 12-Month Membership Applied Automatically",
  newyear23trialext30: "30 Day New Year Trial Extension",
  newyear23trialext14: "14 Day New Year Trial Extension",
  newteamlaunch30day: "Free 30 Day Pass Applied Automatically!",
  adminfree: "Free Ladder Golden Ticket Applied Automatically!",
  black_friday_2024_free_12mo: "Start burning fat and building muscle today.",
  point72_free_12mo: "Free 12-Month Membership Applied Automatically",
  advantage_free_12mo: "Free 12-Month Membership Applied Automatically",
  pagagroup_free_12mo: "Free 12-Month Membership Applied Automatically",
  redbud30day: "Free 30 Day Trial Applied Automatically",
  resetguestpass2025: "Free Guest Pass through Feb 16, 2025",
  agentio_creator_free_12mo: "Free 12-Month Membership Applied Automatically",
};

const promoTitleMessages = {
  redbud30day: "Welcome redbud!",
  advantage_free_12mo: "Welcome ADvantage!",
  pagagroup_free_12mo: "Welcome PagsGroup!",
  point72_free_12mo: "Welcome point72!",
  black_friday_2024_free_12mo: "you've been gifted a free year of ladder!",
  resetguestpass2025: "You Have Unlocked An Exclusive Promo!",
  agentio_creator_free_12mo: "Welcome Youtube Creators!",
};

export default function Notice({ abTest = "", abTestGroup = "" } = {}) {
  const { query, pathname, isReady } = useRouter();
  const { variant } = useExperiment();
  const handleClick = () => {
    captureEvent({
      category: "home.banner.cta",
      action: "find_my_plan_cta_clicked",
      label: "FIND YOUR PLAN",
    });
  };

  const promoCode = query.promoCode || query.promocode;
  const isNutrabolt = query.utm_content === "nutrabolt";
  const promoCodeCopyText = promoCodesToCopyText[promoCode?.toLowerCase?.()];

  function getTitleBannerCopy() {
    if (isNutrabolt) return "welcome nutrabolt!";

    const code = promoCode?.toLowerCase();
    if (code && promoTitleMessages[code]) {
      return promoTitleMessages[code];
    }

    if (promoCodeCopyText) {
      return "you have unlocked an exclusive promo!";
    }

    return "";
  }

  if (!isReady) return null;

  if (!!promoCodeCopyText) {
    return (
      <div className="-mt-1 -mx-2">
        <ShineBorder
          borderWidth={1}
          borderRadius={0}
          className={cn("w-full p-0 bg-card/80", {
            "min-h-[50px]": variant === "referral",
          })}
          color={["#6AD3F4", "#fff", "#6AF478", "#fff", "#D2FF51"]}
        >
          <nav
            className={cn(
              "w-full px-4 py-2 text-sm text-center text-foreground notice center md:text-base lg:px-12 lg:text-md"
            )}
          >
            <AnimatedShinyText className="" shimmerWidth={480}>
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
                  backgroundClip: "text",
                }}
                className="text-white/60 bg-clip-text capitalize"
              >
                {getTitleBannerCopy()}{" "}
                <span
                  style={{
                    backgroundImage:
                      "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
                    backgroundClip: "text",
                  }}
                  className="font-medium block text-white/40 bg-clip-text"
                >
                  {promoCodeCopyText}
                </span>
              </span>
            </AnimatedShinyText>
          </nav>
        </ShineBorder>
      </div>
    );
  }

  if (abTest === "ab-test-home-hero_12_24" && abTestGroup === "variant")
    return null;

  return (
    <nav
      className={cn(
        "w-full px-4 py-3 text-sm text-center bg-background text-foreground border-b border-gray-700 notice center md:text-sm lg:px-12 lg:text-base"
      )}
    >
      <span>
        Think less, lift more!{" "}
        <Link
          onClick={handleClick}
          className="px-1 font-bold text-primary"
          href="/quiz?utm_medium=organic&utm_source=website&utm_campaign=homepage&utm_term=banner&utm_content=homepagesurvey"
        >
          FIND YOUR PLAN
        </Link>
      </span>
    </nav>
  );
}
