// tools/search-for-movements.ts

import { z } from "zod";
import { Tool } from "./constants";

// --- these are the known possible filter values;
// --- if the llm sets any of these filters, it should choose from these sets:

const targetMuscleGroups = [
  "Arms - Biceps",
  "Arms - Forearms",
  "Arms - Deltoids",
  "Arms - Shoulders",
  "Arms - Triceps",
  "Back - Lower Back",
  "Back - Upper Back",
  "Chest",
  "Core (Abs)",
  "Hips",
  "Legs - Calves",
  "Legs - Glutes",
  "Legs - Hamstrings",
  "Legs - Hips",
  "Legs - Quads",
  "N/A",
] as const;

const exerciseTypes = [
  "Strength",
  "Cardio",
  "Flexibility & Mobility",
  "Balance & Stability",
  "Power & Explosive",
  "Mind-Body",
  "N/A",
] as const;

const difficultyLevels = [
  "Beginner",
  "Intermediate",
  "Advanced",
  "N/A",
] as const;

const movementPatterns = [
  "Crawl",
  "Carry",
  "Push",
  "Pull",
  "Squat",
  "Hinge",
  "Lunge",
  "Rotation/Twist",
  "Raise",
  "N/A",
] as const;

const exercisePositions = [
  "Standing",
  "Seated",
  "Lying (On Back)",
  "Lying (On Stomach)",
  "Lying (On Side)",
  "Kneeling",
  "Plank Position",
  "Hanging",
  "Incline",
  "Decline",
  "N/A",
] as const;

const bodyPositions = [
  "Upright",
  "Incline",
  "Decline",
  "Supine (Lying Face Up)",
  "Prone (Lying Face Down)",
  "N/A",
] as const;

const locations = ["Gym-Oriented", "Not Gym-Oriented"] as const;

/*
  we define the tool now:
*/

export const searchForMovements: Tool = {
  name: "searchForMovements",
  description: `
    use this tool to find movements from the ladder library via algolia. 
    you can specify a textual search query, plus filters like exerciseType, difficultyLevel, 
    movementPattern, bodyPosition, etc. 
    returns an array of movement objects with relevant fields. 
    the more specific your filters, the narrower the result set. 
    possible filter values are enumerated in the tool param descriptions below.
  `,
  parameters: z.object({
    query: z
      .string()
      .optional()
      .describe(
        "text query to perform. example: 'barbell bench press', 'spiderman pushup'. if omitted or empty, only filtered results are used."
      ),
    teamID: z
      .string()
      .optional()
      .describe(
        "limit search to a specific team. if omitted, all teams included."
      ),
    primaryMuscleGroup: z
      .enum(targetMuscleGroups)
      .optional()
      .describe(
        `what muscle group to filter by. possible values: ${targetMuscleGroups.join(
          ", "
        )}`
      ),
    exerciseType: z
      .enum(exerciseTypes)
      .optional()
      .describe(`exercise type. possible values: ${exerciseTypes.join(", ")}`),
    difficultyLevel: z
      .enum(difficultyLevels)
      .optional()
      .describe(
        `difficulty level. possible values: ${difficultyLevels.join(", ")}`
      ),
    movementPattern: z
      .enum(movementPatterns)
      .optional()
      .describe(
        `movement pattern. possible values: ${movementPatterns.join(", ")}`
      ),
    exercisePosition: z
      .enum(exercisePositions)
      .optional()
      .describe(
        `exercise position. possible values: ${exercisePositions.join(", ")}`
      ),
    bodyPosition: z
      .enum(bodyPositions)
      .optional()
      .describe(`body position. possible values: ${bodyPositions.join(", ")}`),
    location: z
      .enum(locations)
      .optional()
      .describe(
        `where movement is performed. possible values: ${locations.join(", ")}`
      ),
  }),
  execute: () => null,
};
